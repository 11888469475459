.inputName {
  background-color: transparent;
  color: #000;

  &:focus {
    background-color: transparent;
    color: #000;
  }
}

.unselectable {
  user-select: none;
}

.actions {
  margin-right: 16px;
}

.warning {
  display: flex;
  height: auto;
  align-items: center;
  padding: 16px;
  border: 1px solid #f1556c;
  margin-top: 16px;
  background-color: rgba(241, 85, 108, 0.15);
  border-radius: 4px;
  font-weight: 700;

  .icon {
    margin: 0 8px 0 5px;
    color: #f1556c;
    font-size: 24px;
  }
}
