.logo-rbp {
  position: relative;
  left: -20px;
  display: inline;
  padding: 16px 20px;
  background: #007bff;
  color: #fff;
  font-weight: 900;
  cursor: pointer;
}

.navbar {
  position: fixed !important;
  z-index: 1000;
  top: 0;
  overflow: visible;
  width: 100%;
  background: #323b44;

  .sign-out {
    a {
      display: flex;
      padding: 0;

      i {
        padding-top: 1px;
      }
    }
  }

  &.navbar-light {
    .navbar-brand {
      color: #fff;
    }
  }
}

.dropdown-navbar {
  & > button {
    border: none;
    background: none;
  }
}

.navbar-label {
  color: #fff;
  text-decoration: none !important;

  &:hover {
    color: #fff;
  }
}
