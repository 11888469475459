@import 'assets/scss/theme.scss';

.sidebarTitle {
  display: flex;
  flex-direction: row;
  padding: 10px 0px 15px 10px;

  button {
    margin: 7px 0px 7px 40px;
  }
}

.sidebarLabel {
  display: flex;
  flex-direction: row;

  &Small {
    @media (max-width: 1200px) {
      display: inline !important;
    }
  }

  @media (max-width: 1200px) {
    display: none;
  }
}

.content {
  width: 100%;
}

ul li {
  border-bottom: 1px solid #495561;
}

a:focus {
  text-decoration: none;
}

.nav {
  width: 100%;
  margin-top: 27px;
}
