@import 'assets/scss/theme.scss';

$navbar-height: 50px !default;
$nav-shadow: 4px 4px 6px 0 rgba(0, 0, 0, 0.2) !default;

html {
  overflow-y: scroll;
}

body {
  padding-top: $navbar-height;
}

html,
body {
  padding: 0;
  margin: 0;
  background-color: #323b44 !important;
  -webkit-tap-highlight-color: transparent;
}

.contentPage {
  overflow: hidden;
  min-height: 80vh;
  padding: 60px 20px 65px 20px;
  margin-top: 56px;
  margin-left: 200px;

  &Small {
    margin-left: 60px;
  }

  @media (max-width: 1200px) {
    margin-left: 60px;
  }
}

.main-container {
  display: inline-flex;
  width: 100%;
}

.app-container {
  height: calc(100% - 56px);

  > nav {
    float: left;
  }
}

#app .modal {
  display: block;
}

.navbar {
  &.fixed-top {
    flex-direction: row;
  }

  box-shadow: $nav-shadow;

  .navbar-brand > img {
    display: inline-block;
    height: 30px;
    margin-top: -5px;
    margin-right: 15px;
  }

  .navbar-nav {
    flex-direction: row;
    margin-left: auto;

    .nav-link {
      padding-right: 1rem;
      padding-left: 1rem;
    }
  }

}

$nav-transition: all 0.2s ease-out;

.slide-animation-enter {
  overflow: hidden;
  height: 1px;
  opacity: 0.01;
}

.slide-animation-enter.slide-animation-enter-active {
  overflow: hidden;
  opacity: 1;
  transition: $nav-transition;
  transition-property: opacity min-height, max-height, height, transform;
}

.slide-animation-leave {
  overflow: hidden;
  opacity: 1;
  transition: $nav-transition;
  transition-property: opacity min-height, max-height, height, transform;
}

.slide-animation-leave.slide-animation-leave-active {
  overflow: hidden;
  height: 1px;
  opacity: 0.01;
}

@import '../SideNav/styles.scss';
